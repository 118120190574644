import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useState } from 'react';

import * as customPropTypes from '@/custom-prop-types';
import { trackInteraction } from '@/utils/analytics';

import styles from './Image.module.scss';

/**
 * The Image Component is used to render a responsive and optimized image,
 * which supports art direction and custom aspect ratios
 */
const ImageBase = forwardRef(
    (
        {
            alignVerticle,
            defaultImgSrc,
            defaultImgSrcSet,
            defaultWebPSrcSet,
            defaultSizes,
            defaultAspectRatio,
            mobileAspectRatio,
            sizes,
            height,
            width,
            widths,
            alt,
            className,
            lazyEnabled,
            loading,
            onError,
            fallbackSrc,
            roundedCorners,
            decorative,
            src,
            srcset,
            ...props
        },
        ref,
    ) => {
        // states to handle the image error
        const [hasError, setHasError] = useState(false);
        const [hideImage, setHideImage] = useState(false);
        const [errorHasBeenCalled, setErrorHasBeenCalled] = useState(false);

        const imgSrc = src || defaultImgSrc;
        const imgSrcSet = srcset || defaultImgSrcSet;

        // callback to set the error state of an image if it fails to load
        const handleError = useCallback(
            (e) => {
                if (!errorHasBeenCalled) {
                    if (fallbackSrc) {
                        setHasError(true);
                    } else {
                        setHideImage(true);
                    }
                    if (onError) onError(e);
                } else {
                    setHideImage(true);
                }
                setErrorHasBeenCalled(true);
            },
            [errorHasBeenCalled, fallbackSrc, onError],
        );

        return (
            <picture
                ref={ref}
                title={props.title}
                className={classnames(
                    styles.wrapper,
                    hideImage && styles.hideImage,
                    roundedCorners && styles.roundedCorners,
                    className,
                )}
            >
                {/* render source elements when available */}
                {!!imgSrcSet && <source sizes={sizes || ''} srcSet={imgSrcSet} />}
                {!!defaultWebPSrcSet && <source sizes={sizes || ''} srcSet={defaultWebPSrcSet} />}
                {/* image tag is used as fallback for browsers that do not support source */}
                <img
                    className={classnames(styles.image, alignVerticle && styles[alignVerticle])}
                    src={hasError && fallbackSrc ? fallbackSrc : imgSrc}
                    height={height}
                    width={width}
                    alt={decorative ? '' : alt}
                    role={!alt || decorative ? 'presentation' : null}
                    style={{
                        '--defaultAspectRatio': defaultAspectRatio,
                        '--mobileAspectRatio': mobileAspectRatio,
                    }}
                    onError={handleError}
                    decorative={decorative ? 'true' : 'false'}
                    loading={
                        lazyEnabled !== undefined
                            ? lazyEnabled
                                ? 'lazy'
                                : 'eager'
                            : loading === 'lazy'
                              ? 'lazy'
                              : 'eager'
                    }
                />
            </picture>
        );
    },
);

const Image = forwardRef((props, ref) => {
    const { destinationUrl, ariaLabel, alt, analytics } = props;
    const handleClick = () => {
        //analytics
        trackInteraction({
            ...analytics,
            actionLabel: alt || ariaLabel,
            selector: analytics?.selector || 'a',
        });
    };
    return destinationUrl ? (
        <a
            href={destinationUrl}
            aria-label={ariaLabel}
            onClick={analytics ? handleClick : undefined}
            data-trigger={analytics ? analytics?.selector || 'a' : undefined}
        >
            <ImageBase {...props} ref={ref} />
        </a>
    ) : (
        <ImageBase {...props} ref={ref} />
    );
});

Image.ASPECT_RATIO = {
    DEFAULT: '',
    AUTO: 'auto',
    '1:1': '1/1',
    '2:3': '2/3',
    '3:2': '3/2',
    '4:3': '4/3',
    '9:16': '9/16',
    '16:9': '16/9',
};

Image.ALIGN_VERTICLE = {
    BOTTOM: 'image--align-vertical-bottom',
    CENTER: 'image--align-vertical-center',
    TOP: 'image--align-vertical-top',
};

Image.propTypes = {
    /**
     * Alignment of the image
     */
    alignVerticle: PropTypes.oneOf(Object.values(Image.ALIGN_VERTICLE)),
    /**
     * Alt Text
     */
    alt: PropTypes.string,
    /**
     * Default Aspect Ratio
     */
    defaultAspectRatio: customPropTypes.aspectRatio,
    /**
     * Default Image Src
     */
    defaultImgSrc: PropTypes.string,
    /**
     * Default Image Srcset
     */
    defaultImgSrcSet: PropTypes.string,
    /**
     * Default WebP Image Srcset
     */
    defaultWebPSrcSet: PropTypes.string,
    /**
     * On Error handler
     */
    onError: PropTypes.func,
    /**
     * Fallback image source
     */
    fallbackSrc: PropTypes.string,
    /**
     * Sizes for responsive images
     */
    sizes: PropTypes.string,
    /**
     * Source image height
     */
    height: PropTypes.string,
    /**
     * Source image width
     */
    width: PropTypes.string,
    /**
     * Widths for responsive images
     */
    widths: PropTypes.arrayOf(PropTypes.number),
    /**
     * Lazy loading enabled
     * */
    lazyEnabled: PropTypes.bool,
    /**
     * Loading attribute
     */
    loading: PropTypes.oneOf(['lazy', 'eager']),
    /**
     * Rounded corners
     */
    roundedCorners: PropTypes.bool,
    /**
     * Decorative image
     */
    decorative: PropTypes.bool,
    /**
     * Source image
     */
    src: PropTypes.string,
    /**
     * SourceSet image set
     */
    srcset: PropTypes.string,
    /**
     * link href
     */
    destinationUrl: PropTypes.string,
    /**
     * link aria label
     */
    ariaLabel: PropTypes.string,
};

Image.defaultProps = {
    alignVerticle: Image.ALIGN_VERTICLE.CENTER,
    srcset: '',
    defaultImgSrcSet: '',
    defaultWebPSrcSet: '',
    lazyEnabled: false,
    loading: 'lazy',
    roundedCorners: false,
    decorative: false,
};

export default Image;
